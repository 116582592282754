<template>

  <div class="row" ref="ProjectsForm">
    <div class="col-xs-12 col-md-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <div class="row">
                <div class="col-md-6 col-sm-12">
                  <ValidationProvider
                    vid="title"
                    rules="required"
                    name="The Title"
                    v-slot="{ passed, failed,errors }">
                    <fg-input type="text"
                              :error="failed ? errors[0]: null"
                              label="title"
                              name="title"
                              fou
                              @keyup="generateURL"
                              v-model="formData.title">
                    </fg-input>
                  </ValidationProvider>
                  <ValidationProvider
                    vid="author"
                    rules="required"
                    name="The Author"
                    v-slot="{ passed, failed,errors }">
                    <fg-input type="text"
                              :error="failed ? errors[0]: null"
                              label="author"
                              name="author"
                              fou

                              v-model="formData.author">
                    </fg-input>
                  </ValidationProvider>
                </div>
              <div class="col-md-6 col-sm-12">
                <ValidationProvider
                  vid="url"
                  rules="required"
                  name="The URL"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="url"
                            name="url"
                            fou
                            v-model="formData.url">
                  </fg-input>
                </ValidationProvider>
                <div>
                  <label class="card-label">Date</label>
                  <fg-input v-model="formData.date"
                            value-format="YYYY-MM-DD HH:MM:SS"
                            type="datetime-local"
                            placeholder="Chose  date"
                  >
                  </fg-input>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-between">
              <div class="form-group pt-4">
                <label>Is Active</label>&nbsp;
                <l-switch v-model="formData.is_active">
                  <i class="fa fa-check" slot="on"></i>
                  <i class="fa fa-times" slot="off"></i>
                </l-switch>
              </div>
              <div class="form-group pt-4">
                <label>Is Featured</label>&nbsp;
                <l-switch v-model="formData.is_featured">
                  <i class="fa fa-check" slot="on"></i>
                  <i class="fa fa-times" slot="off"></i>
                </l-switch>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 col-sm-12">
                <ValidationProvider
                  vid="excerpt"
                  rules=""
                  name="The Excerpt"
                  v-slot="{ passed, failed,errors }">
                  <fg-text type="text"
                           :error="failed ? errors[0]: null"
                           label="excerpt"
                           name="excerpt"
                           fou
                           v-model="formData.excerpt">
                  </fg-text>
                </ValidationProvider>
                <div class="form-group">
                  <label>Image - ( width: 983  ,  height: 553 )</label>
                  <el-tooltip placement="right" v-if="getBannerImageInfo()">
                    <div slot="content">{{getBannerImageInfo()}}</div>
                    <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                  </el-tooltip>
                  <prime-uploader
                    :preview-width="'200px'"
                    :preview-height="'200px'"
                    :elementNum="1"
                    v-model="formData.image"
                  >
                  </prime-uploader>
                </div>
                <!--                <ValidationProvider-->
                <!--                        vid="related_id"-->
                <!--                        rules=""-->
                <!--                        name="The Related News"-->
                <!--                        v-slot="{ passed, failed,errors }">-->
                <!--                  <fg-select-->
                <!--                          name="related_id"-->
                <!--                          size="large"-->
                <!--                          multiple-->
                <!--                          filterable-->
                <!--                          clearable-->
                <!--                          placeholder="Related News"-->
                <!--                          :error="failed ? errors[0]: null"-->
                <!--                          :input-classes="'select-info'"-->
                <!--                          :label="'Related News'"-->
                <!--                          :list="newsList"-->
                <!--                          :listItemLabel="'name'"-->
                <!--                          :listItemValue="'id'"-->
                <!--                          v-model="formData.relatedNews">-->
                <!--                  </fg-select>-->
                <!--                </ValidationProvider>-->
              </div>
              <div class="col-md-12 col-sm-12">
                <div class="form-group">
                  <label>Content</label>
                  <editor
                    v-model="formData.content"
                    :api-key="editorKey"
                    :init='{
                    height: 400,
                    paste_data_images: true,
                    plugins: [
                    "advlist autolink lists link image charmap print preview hr anchor pagebreak",
                     "searchreplace wordcount visualblocks visualchars code fullscreen",
                    "insertdatetime media nonbreaking save table contextmenu directionality",
                    "emoticons template paste textcolor colorpicker textpattern"
                    ],
                    toolbar1: "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
                    toolbar2: "print preview media | forecolor backcolor emoticons",
                    image_advtab: true,
                  }'
                  />
                </div>

              </div>
              <div class="col-md-12 col-sm-12">
                <div class="form-group">
                  <label>Gallery</label>
                  <el-tooltip placement="right" v-if="getBannerImageInfo()">
                    <div slot="content">{{getBannerImageInfo()}}</div>
                    <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                  </el-tooltip>
                  <prime-uploader
                    :preview-width="'200px'"
                    :preview-height="'200px'"
                    :elementNum="5"
                    v-model="formData.gallery"
                  >
                  </prime-uploader>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>
            <l-button @click="$router.push('/projects/list/'+this.formData.lang)" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>

      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Select, Option,Tooltip} from 'element-ui';
import FgSelect from "@/components/Inputs/formGroupSelect";
import FormGroupTextArea, {ImageUploader, Switch as LSwitch} from "@/components";
import Editor from '@tinymce/tinymce-vue'
import {mapGetters} from "vuex";
import {PrimeUploader} from '@/components';
import {PrimeVideoUploader} from '@/components';



extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    FormGroupTextArea,
    [Option.name]: Option,
    [Select.name]: Select,
    [Tooltip.name]: Tooltip,
    FgSelect,
    LSwitch,
    PrimeUploader,
    PrimeVideoUploader,
    'editor': Editor
  },

  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,

      formData: {
        title: "",
        author: "",
        excerpt: "",
        url: "",
        content: "",
        image: "",
        video: "",
        file: "",
        start_date: null,
        end_date: null,
        date: null,
        is_active: true,
        is_featured: true,
        gallery: [],
        lang:'en'
      },
    };
  },

  computed: {
    ...mapGetters({
      mediaInfo: "global/mediaInfo"
    })
  },
  created(){
    const path = window.location.href;
    this.formData.lang = path.substring(path.lastIndexOf('/') + 1)
  },

  mounted() {
    this.loader = this.$loading.show({
      container: this.$refs.ProjectsForm
    });
    this.id = this.$route.params['id'];
    if (this.id !== undefined) {
      this.editMode = true;
      this.formTitle = "Edit Projects";
      this.getProjects();
    } else {
      this.editMode = false;
      this.formTitle = "Add Projects";
      this.loader.hide();
    }
  },

  methods: {
    getProjects() {
      this.axios.post("projects/get/" + this.id ,this.formData).then((response) => {
        this.formData = response.data;
        this.formData.gallery = response.data.gallery;
        this.formData.start_date = (this.formData.start_date)? this.formData.start_date.replace(' ', 'T'):this.formData.start_date;
        this.formData.end_date = (this.formData.end_date)? this.formData.end_date.replace(' ', 'T'):this.formData.end_date;
        this.formData.date = (this.formData.date)? this.formData.date.replace(' ', 'T'):this.formData.date;
        const path = window.location.href;
        this.formData.lang = path.substring(path.lastIndexOf('/') + 1)

        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Projects Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();

        } else {
          console.error(error);
        }
      })
    },


    submit() {
      let request;
      let successMessage;
      this.submitting = true;

      if (this.editMode === true) {
        request = this.axios.put("projects/update/" + this.id, this.formData);
        successMessage = "Project Updated Successfully";
      } else {
        request = this.axios.post("projects/create", this.formData);
        successMessage = "Project Added Successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/projects/list/en");
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          console.log(error.response)
        }
      }).finally(() => {
        this.submitting = false;
      })
    },

    getBannerImageInfo() {
      if(this.mediaInfo && this.mediaInfo["project_image"]) {
        return this.mediaInfo["project_image"];
      } else {
        return "";
      }
    },
    generateURL() {

      let newUrl = '';
      if (this.formData.title) {
        newUrl = this.formData.title.replace(/\s+/g, "-").toLowerCase();
        newUrl = newUrl.replace(/[^ءأ-يa-zA-Z0-9-]/g, "");
        newUrl = newUrl.replace(/([-]+)/g, "-");
      }

      this.formData.url = newUrl;
    },}
}
</script>

<style scoped>

.extra-fields{
  box-shadow: rgb(235 232 232) 1px 1px 20px 0px, rgb(235 232 232) -1px -1px 20px 0px;
  padding: 0;
  padding-bottom: 3px;

}.extra-fields >h4{
   padding:20px;
 }
</style>
